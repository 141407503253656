<template>
    <v-theme-provider dark>
        <!-- vlastnost dark mění barvu kartiček -->

        <base-section style="background-color: #edefef">
        <v-container
            class="black--text"
        >
            <v-row>
                <v-col cols="12" md="8">
                    <div class="text-overline primary--text font-weight-bold">
                        Jsme
                    </div>
                    <h2 class="display-1">
                        AutoCásek, s.r.o.
                    </h2>
                    <picture>
                        <source type="image/avif" alt="Zakladatel a jednatel společnosti AutoCásek, s.r.o. Roman Cásek" :srcset="require('@/assets/mrcasek_portrait.avif')">
                        <img class="hidden-lg-and-up about--img-desktop" alt="Zakladatel a jednatel společnosti AutoCásek, s.r.o. Roman Cásek" :src="require('@/assets/mrcasek_portrait.jpg')">
                    </picture>
                    <div class="body-1 mt-10">
                        <p>
                            Společnost vznikla v roce 2019. V názvu nese příjmení zakladatele a majitele Romana Cáska, který se zabývá problematikou mobility více než třicet let. Firma sází na dlouholeté osobní kontakty a zkušenosti majitele.
                            Smyslem společnosti je využití možnosti pronájmu vozidel komfortním způsobem, tj. bez starosti, s odborným poradenstvím a veškerými službami. Společnost nabízí k užívání pouze nová vozidla různých značek. Z velké části se specializuje na vozy koncernu Volkswagen.
                            Posláním společnosti je nabídnout služby s osobním přístupem v nejvyšší kvalitě. 
                        </p>
                        <div class="mt-10">
                            <p class="text-overline">
                                Vize majitele
                            </p>
                            <p>
                                „Během mnohaleté praxe jsem zjistil, že v současné době chybí na automobilovém trhu pro uživatele dostatek skutečně kvalitních služeb v oblasti dlouhodobého pronájmu vozidel. Mým cílem je vytvořit pro zákazníky fungující služby bez zbytečných „ALE“...“
                            </p>
                            <div class="text-right">
                                <picture>
                                 <!--source type="image/avif" alt="Podpis zakladatele firmy" :srcset="require('@/assets/signature.avif')"/> nema cenu-->
                                 <img class="about--img-signature" alt="Podpis zakladatele firmy" :src="require('@/assets/signature_small.png')" />
                                 </picture>
                            </div>
                        </div>
                    </div>
                    
                </v-col>
                <v-col class="my-auto text-center hidden-md-and-down">
                    <picture>
                        <source type="image/avif" alt="Zakladatel a jednatel společnosti AutoCásek, s.r.o. Roman Cásek" :srcset="require('@/assets/mrcasek_portrait.avif')"/>
                        <img class="about--img-mobile" alt="Zakladatel a jednatel společnosti AutoCásek, s.r.o. Roman Cásek" :src="require('@/assets/mrcasek_portrait.jpg')">
                    </picture>
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</v-theme-provider>
</template>
<script>
export default {
	name: "AboutMrcasek",
};
</script>

<style scoped>
  .about--img-desktop {
      width: 100%;
      height: auto;
  }
  .about--img-mobile {
      width: 90%;
      height: auto;
  }
  .about--img-signature {
      max-width: 200px;
      height: auto;
  }
</style>
